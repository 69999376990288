import * as React from "react";
import { Typography, Stack, Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

// components
import Section from "../utils/section";

const SalvaFreschezza = () => {
    return (
        <Section>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={12} md={4}>
                    <StaticImage
                        imgStyle={{ borderRadius: 16 }}
                        src="../images/strati-salvietta-monouso.png"
                        alt="Confezioni salvafreschezza"
                        layout="fullWidth"
                    />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="h2">
                            Confezioni salvafreschezza
                        </Typography>
                        <Typography>
                            Le nostre salviettine rinfrescanti sono realizzate con un <strong>materiale resistente</strong>, ma allo stesso modo morbido e
                            delicato sulla pelle. Ogni salvietta monouso è <strong>confezionata singolarmente</strong> in una comoda bustina rivestita da 3
                            strati per garantire un'ottimale conservazione della fragranza e dell'umidità
                        </Typography>
                        <ul>
                            <li>
                                <strong>Rivestimento</strong> : personalizzabile utilizzando 3 tipo di carta diversa: carta avana, paglierino o bianca.{" "}
                            </li>
                            <li>
                                <strong>Alluminio</strong>: protegge il prodotto dai raggi solari e lo mantiene umido.
                            </li>
                            <li>
                                <strong>Nylon</strong>: preserva l'umidità.
                            </li>
                        </ul>
                    </Stack>
                </Grid>
            </Grid>
        </Section>
    );
};

export default SalvaFreschezza;

import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Button, Box, Grid, Typography, Stack } from "@mui/material";

// components
import Link from "../utils/link";
import Page from "../utils/page";
import Hero from "../utils/hero";
import theme from "../utils/theme";
import SalvaFreschezza from "../components/salvafreschezza";
import Section from "../utils/section";
import Personalizza from "../components/personalizza";

// markup
const SalviettePage = () => {
    const schema = [
        {
            "@context": "https://schema.org/",
            "@type": "Product",
            name: "Salviette",
            image: "",
            description:
                "Packaging neutro e curato nel dettaglio, adatto ad ogni tipo di situazione ed attività. Realizziamo anche salviette personalizzate per dare un tocco originale al tuo locale.",
            brand: {
                "@type": "Brand",
                name: "Mettifogo",
            },
        },
    ];

    return (
        <Page
            title="Salviette monouso per ristoranti, bar e hotel"
            description="Packaging neutro e curato nel dettaglio, adatto ad ogni tipo di situazione ed attività. Realizziamo anche salviette personalizzate per dare un tocco originale al tuo locale."
            type="CollectionPage"
            readMore={["/contatti/#modulo-contatto", "/richiedi-campione-gratuito"]}
            image="/salviette-monouso.jpg"
            schema={schema}
        >
            <Hero
                title="Salviette monouso"
                description={
                    <>
                        Packaging neutro e curato nel dettaglio, adatto ad ogni tipo di situazione ed attività. Realizziamo anche salviette personalizzate per
                        dare un tocco originale al tuo locale.
                    </>
                }
                background={theme.palette.primary.main}
                backgroundImage={
                    <StaticImage
                        style={{ height: "100%" }}
                        quality={100}
                        src="../images/backgrounds/fragranza-menta.jpg"
                        alt="Fragranza menta"
                        layout="fullWidth"
                    />
                }
            />

            <Section>
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <Stack spacing={2}>
                            <Typography variant="h2" component="h2">
                                La nostra gamma
                            </Typography>

                            <Typography>
                                Dai profumi caldi e avvolgenti della <strong>cannella</strong>, alla freschezza del <strong>limone</strong> e al profumo
                                orientale della mirra.
                            </Typography>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} textAlign="center">
                        <Box mb={2}>
                            <StaticImage
                                imgStyle={{ borderRadius: 16 }}
                                src="../images/products/salvietta-monouso-gusto-limone.jpg"
                                alt="Salvietta limone"
                                layout="fullWidth"
                            />
                        </Box>
                        <Typography variant="h5" gutterBottom component="h2" color="primary">
                            Limone
                        </Typography>
                        <Typography>Profumo agrumato</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} textAlign="center">
                        <Box mb={2}>
                            <StaticImage
                                imgStyle={{ borderRadius: 16 }}
                                src="../images/products/salvietta-monouso-gusto-lime-e-cannella.jpg"
                                alt="Salvietta lime e cannella"
                                layout="fullWidth"
                            />
                        </Box>
                        <Typography variant="h5" gutterBottom component="h2" color="primary">
                            Lime e cannella
                        </Typography>
                        <Typography>Profumo agrumato e speziato</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} textAlign="center">
                        <Box mb={2}>
                            <StaticImage
                                imgStyle={{ borderRadius: 16 }}
                                src="../images/products/salvietta-monouso-gusto-mojito.jpg"
                                alt="Salvietta mojito"
                                layout="fullWidth"
                            />
                        </Box>
                        <Typography variant="h5" gutterBottom component="h2" color="primary">
                            Mojito
                        </Typography>
                        <Typography>Profumo fresco e fruttato</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} textAlign="center">
                        <Box mb={2}>
                            <StaticImage
                                imgStyle={{ borderRadius: 16 }}
                                src="../images/products/salvietta-monouso-gusto-the-verde.jpg"
                                alt="Salvietta tè verde"
                                layout="fullWidth"
                            />
                        </Box>
                        <Typography variant="h5" gutterBottom component="h2" color="primary">
                            Tè verde
                        </Typography>
                        <Typography>Profumo orientale</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} textAlign="center">
                        <Box mb={2}>
                            <StaticImage
                                imgStyle={{ borderRadius: 16 }}
                                src="../images/products/salvietta-monouso-gusto-coloniale.jpg"
                                alt="Salvietta coloniale"
                                layout="fullWidth"
                            />
                        </Box>
                        <Typography variant="h5" gutterBottom component="h2" color="primary">
                            Coloniale
                        </Typography>
                        <Typography>
                            Profumo orientale e agrumato
                            <br /> (mirra e bergamotto)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} textAlign="center">
                        <Box mb={2}>
                            <StaticImage
                                imgStyle={{ borderRadius: 16 }}
                                src="../images/products/salvietta-monouso-gusto-sweet-fruit.jpg"
                                alt="Salvietta sweet fruit"
                                layout="fullWidth"
                            />
                        </Box>
                        <Typography variant="h5" gutterBottom component="h2" color="primary">
                            Sweet fruit
                        </Typography>
                        <Typography>
                            Profumo dolce, fruttato e agrumato
                            <br /> (arancia e mela)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} textAlign="center">
                        <Box mb={2}>
                            <StaticImage
                                imgStyle={{ borderRadius: 16 }}
                                src="../images/products/salvietta-monouso-igenizzante.jpg"
                                alt="Salvietta igenizzante"
                                layout="fullWidth"
                            />
                        </Box>
                        <Typography variant="h5" gutterBottom component="h2" color="primary">
                            Igenizzante
                        </Typography>
                        <Typography>
                            Profumo fresco, agrumato
                            <br /> <strong>Base Alcool 70%</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} textAlign="center">
                        <Box mb={2}>
                            <StaticImage
                                imgStyle={{ borderRadius: 16 }}
                                src="../images/products/salvietta-monouso-personalizzata.jpg"
                                alt="Salvietta personalizzata"
                                layout="fullWidth"
                            />
                        </Box>
                        <Typography variant="h5" gutterBottom component="h2" color="primary">
                            Personalizzata
                        </Typography>
                        <Typography>Scegli fra tanti gusti</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Stack spacing={2}>
                            <Typography variant="h2" component="h2">
                                Caratteristiche
                            </Typography>
                            <Typography>
                                I nostri prodotti sono stati progettati per soddisfare diversi requisiti. Uno di questi è l'
                                <strong>eliminazione degli odori</strong> poco gradevoli dalla pelle come quelli del pesce. Allo stesso tempo sono ideali per{" "}
                                <strong>rinfrescare le mani</strong> alla fine del pasto.
                            </Typography>
                            <Typography>
                                Grazie alle <strong>ridotte dimensioni della bustina</strong>, la potete includere nella mise en place del vostro{" "}
                                <strong>ristorante o locale</strong>. Possiamo anche metterla a <strong>disposizione dei clienti</strong> in un comodo
                                contenitore.
                            </Typography>
                            <Typography>
                                Le dimensioni sono state studiate per riuscire a pulire e rinfrescare le mani in modo efficiente evitando qualsiasi spreco.
                            </Typography>
                            <Typography>
                                Recentemente abbiamo sviluppato la nuova linea di salviette igienizzanti, la scelta giusta se vuoi far sentire i tuoi clienti al
                                sicuro.
                            </Typography>
                            <Typography component="div">
                                <ul>
                                    <li>
                                        <strong>Dimensione standard:</strong> lato lungo 10 cm - lato corto 6,6 cm
                                    </li>
                                    <li>
                                        <strong>Dimensione maxi:</strong> lato lungo 14 cm - lato corto 7,3 cm
                                    </li>
                                    <li>
                                        <strong>Confezioni:</strong> 500 pz, 900 pz (150 pz x 6 confezioni), 1.000 pz
                                    </li>
                                    <li>realizzati in 100</li>
                                </ul>
                            </Typography>
                            <Box mt={2}>
                                <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                                    <Link type="internal" href="/contatti/#modulo-contatto" title="scopri di più su richiedi preventivo" underline="none">
                                        <Button variant="contained" color="primary" size="large">
                                            Richiedi un preventivo
                                        </Button>
                                    </Link>
                                    <Link type="internal" href="/richiedi-campione-gratuito" title="scopri di più sui campioni gratuiti" underline="none">
                                        <Button variant="outlined" color="info" size="large">
                                            Richiedi un campione gratuito
                                        </Button>
                                    </Link>
                                </Stack>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Section>

            <SalvaFreschezza />

            <Section
                maxWidth="md"
                background={theme.palette.primary.main}
                backgroundImage={
                    <StaticImage
                        style={{ height: "100%" }}
                        quality={100}
                        src="../images/backgrounds/fragranza-menta.jpg"
                        alt="Fragranza menta"
                        layout="fullWidth"
                    />
                }
            >
                <Stack spacing={2} textAlign="center">
                    <Typography variant="h3" component="h2">
                        Una vasta scelta di gusti
                    </Typography>
                    <Typography>
                        Ogni realtà ha dei profumi che la rispecchiano, ad esempio un ristorante di pesce sicuramente predilice il limone o lime & cannella; un
                        sunset bar in riva al mare ha come fragranza ideale quella del mojito; un piccolo e curato agriturismo è perfetto per servire la
                        salvietta profumata al coloniale (mirra e bergamotto).
                    </Typography>

                    <Box>
                        <Link type="internal" href="/richiedi-campione-gratuito" title="Scopri di più su campione gratuito" underline="none">
                            <Button size="large" variant="contained">
                                Prova i nostri gusti
                            </Button>
                        </Link>
                    </Box>
                </Stack>
            </Section>

            <Personalizza />
        </Page>
    );
};

export default SalviettePage;
